<template>
  <h1 class="category">Categoría 1</h1>
  <div class="category-items">
    <button class="btn btn-outline-primary">Sub Categoría</button>
    <button class="btn btn-outline-primary">Sub Categoría</button>
    <button class="btn btn-outline-primary">Sub Categoría</button>
    <button class="btn btn-outline-primary">Sub Categoría</button>
    <button class="btn btn-outline-primary">Sub Categoría</button>
  </div>
</template>
